import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { ProvideAuth } from "../auth.js";
import loadable from '@loadable/component';

const Nav = loadable(() => import('../Nav'));
const Admin = loadable(() => import('../Admin'));
const Login = loadable(() => import('../Login'));
const Register = loadable(() => import('../Register'));
const Profile = loadable(() => import('../Profile'));
const Home = loadable(() => import('../Home'));
const Rules = loadable(() => import('../Rules'));

export default function App() {
  return (
    <ProvideAuth>
      <Router>
        <div>
          <Nav />
          <Switch>
            <div>
              <div className="container-fluid">
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/admin">
                  <Admin />
                </Route>
                <Route path="/rules-payouts">
                  <Rules />
                </Route>
              </div>
              <div className="container">
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/register" >
                  <Register />
                </Route>
                <Route path="/profile" >
                  <Profile />
                </Route>
              </div>
            </div>
          </Switch>
        </div>
      </Router>
    </ProvideAuth>
  );
}
